<template>
  <div class="pa-0">
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="grid justify-items-center px-0 py-10 sm:mr-3" v-else>
      <v-col
        cols="11"
        class="pa-0 mt-10 pt-10"
        v-if="Object.keys(items).length > 0 || itemsBlog.length > 0">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-10">
            <span class="font-bold text-3xl text-capitalize" v-if="bahasa"
              >Karir</span
            >
            <span class="font-bold text-3xl text-capitalize" v-else
              >Career</span
            >
          </v-col>
        </v-row>
        <v-tabs v-model="tab" class="stories px-3">
          <v-tab href="#tab-1" class="">
            <span v-if="bahasa">Konsultan</span>
            <span v-else>Consultant</span>
          </v-tab>
          <!-- <v-tab href="#tab-2" class="">
            <span v-if="bahasa">Barang / Jasa Lainnya</span>
            <span v-else>Blogs</span>
          </v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-0">
            <v-row class="mt-3" v-if="isLoad">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="!isLoad && Object.keys(items).length > 0">
              <div></div>
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                @click="
                  goApply(
                    'https://procurement.bpdlh.id/index.php/konsultan/cek_tor/' +
                      item.id_konsultan
                  )
                "
                v-for="item in items"
                :key="item.id_konsultan">
                <v-img
                  :src="require('@/assets/images/thumbnail.jpg')"
                  class="rounded align-center pa-5 w-screen"
                  height="250">
                  <v-col
                    cols="12"
                    class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                    <div class="text-sm">
                      {{ "Pengadaan Jasa Konsultasi" }}
                    </div>
                    <div class="text-xl sm:text-xl tracking-wide py-2">
                      {{ item.nama_konsultan || "-" }}
                    </div>
                    <div class="text-base">Project : {{ item.project }}</div>
                    <div class="text-base">
                      Batas akhir pendaftaran : {{ item.tgl_tutup }}
                    </div>
                    <v-btn
                      class="btn-primary-white mt-3 font-bold text-capitalize">
                      <span>{{ bahasa ? "Daftar" : "Apply" }}</span>
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>
            </v-row>
            <v-row
              class="text-right mt-3 py-5"
              v-if="Object.keys(items).length > 0">
              <v-spacer />
              <v-col cols="2">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-2" class="pa-0">
            <v-row class="mt-3" v-if="isLoadBlog">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="!isLoad && Object.keys(itemsBlog).length > 0">
              <div class="white grid justify-items-center px-0 text-left">
                <v-col
                  cols="10"
                  sm="6"
                  class="pa-0 pt-10"
                  v-if="Object.keys(itemsBlog).length > 0">
                  <div class="mt-5 mb-10">
                    <div class="font-lora text-base mb-8" v-if="bahasa">
                      BPDLH sedang merekrut untuk pengedaan berikut:
                    </div>
                    <div class="font-lora text-base mb-8" v-else>
                      BPDLH is hiring for these procurements:
                    </div>
                    <div
                      class="pa-0 cursor-pointer"
                      v-for="item in itemsBlog.data"
                      :key="item.id"
                      @click="goTo(item.id)">
                      <div class="font-bold text-lg my-2 text-left">
                        {{ item.title || "-" }}
                      </div>
                      <div class="text-sm pb-3 pt-0">
                        {{ item.lastUpdate || "-" }}
                      </div>
                    </div>
                  </div>
                  <!-- <div v-if="itemsBlog">
                  </div> -->
                </v-col>
              </div>
              <v-col
                cols="11"
                class="pa-0 mt-10 py-10"
                v-if="Object.keys(itemsBlog).length === 0">
                <v-row>
                  <v-col cols="12" sm="6">
                    <img src="@/assets/images/405.png" width="100%" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="text-black flex flex-wrap content-center">
                    <v-col cols="12" class="text-3xl font-bold pl-0">{{
                      bahasa
                        ? "Tidak ada data pengadaan yang tersedia"
                        : "No Procurement Data Available"
                    }}</v-col>
                    <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                      {{ bahasa ? "kembali ke beranda" : "back to home" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              class="text-left mt-3 py-5"
              v-if="Object.keys(itemsBlog).length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageBlog"
                  :length="pageCountBlog"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="11" class="pa-0 mt-10 py-10" v-else>
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{
              bahasa
                ? "Tidak Ada Data Karir yang Tersedia"
                : "No Careers Data Available"
            }}</v-col>
            <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
              {{ bahasa ? "kembali ke beranda" : "back to home" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      per_page: 10,
      tab: null,
      isLoad: false,
      isLoadBlog: false,
      items: [],
      itemsBlog: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      pageBlog: 1,
      pageCountBlog: 0,
    };
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    bahasa() {
      this.getDataStory();
      this.getDataBlog();
    },
    page() {
      this.getDataStory();
    },
    pageBlog() {
      this.getDataBlog();
    },
  },
  mounted() {
    this.getDataStory();
    this.getDataBlog();
  },
  methods: {
    goApply(url) {
      window.open(url);
    },
    goTo(link, type, param) {
      this.$router.push({
        name: link,
        params: {
          type: type,
          id: param,
        },
      });
    },
    async getDataStory() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        type: "kisah",
        page: this.page,
        per_page: this.per_page,
      };

      await axios
        .get(this.baseUrl + "/data-prorek", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.items = response.data.data.data;
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDataBlog() {
      this.isLoadBlog = true;
      let param = {
        lang: this.language,
        type: "blog",
        page: this.pageBlog,
      };
      await axios
        .get(this.baseUrl + "/procurement", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.itemsBlog = response.data.data;
            this.pageBlog = response.data.data.current_page;
            this.pageCountBlog = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadBlog = false));
    },
  },
};
</script>
